export default {
  init() {

    const branch_select = document.querySelector('#branch-selector');
    const team_member_index = document.querySelector('.team-member-index');
    const team_members = team_member_index.querySelectorAll('.team-member-card');
    const currentBranchSpan = document.querySelector('[data-current-branch-title]');
    const showBranchList = document.querySelector('[data-show-branches-list]');
    const buttons = branch_select.querySelectorAll('button');
    let selectorIsOpen = false;

    showBranchList.addEventListener('click', () => {
      displayBranchList();

      // Start listening for outside clicks only after the selector is shown
      setTimeout(() => {
        selectorIsOpen = true;
      }, 0);
    });

    buttons.forEach(button => {
      button.addEventListener('click', () => displayNewTeamMembers(button));
    });

    // Check URL for branch parameter and display correct branch
    const urlParams = new URLSearchParams(window.location.search);
    const branchFromUrl = urlParams.get('branch');

    if (branchFromUrl) {
      const matchingButton = Array.from(buttons).find(button =>
        button.getAttribute('data-branch-slug') === branchFromUrl
      );
      if (matchingButton) {
        displayNewTeamMembers(matchingButton);
      } else {
        displayNewTeamMembers(buttons[0]);
      }
    } else {
      displayNewTeamMembers(buttons[0]);
    }

    /**
     * Shows the branch selection dropdown list
     */
    function displayBranchList() {
      branch_select.classList.toggle('hidden', false);
    }

    /**
     * Filters and displays team members based on selected branch
     * Updates UI elements to reflect the current selection
     * @param {HTMLElement} button - The clicked branch selection button
     */
    function displayNewTeamMembers(button) {

      // Get branch data
      const selected_branch_slug = button.getAttribute('data-branch-slug');
      const selected_branch_name = button.getAttribute('data-branch');

      // Set selected branch title and hide list
      currentBranchSpan.innerHTML = selected_branch_name;
      branch_select.classList.add('hidden');
      selectorIsOpen = false;

      // Toggle active button class
      branch_select.querySelectorAll('[data-branch]').forEach(branchButton => {

        branchButton.classList.toggle('text-gray-300', branchButton === button)

      });

      // Toggle team cards
      team_members.forEach(member => {

        const slug = member.querySelector('[data-branch]').dataset.branchSlug;
        const hideMember = !(slug == selected_branch_slug || 'view-all' == selected_branch_slug);

        member.classList.toggle('hidden', hideMember);

      });

      // Update URL with selected branch
      updateUrl(selected_branch_slug);
    }

    /**
     * Handles clicks outside the branch selector to close it
     * Only active when selector is open
     * @param {Event} e - Click event object
     */
    window.addEventListener('click', function(e) {
      if (selectorIsOpen) {
        if (!branch_select.contains(e.target) && e.target !== showBranchList) {
          branch_select.classList.add('hidden');
          selectorIsOpen = false;
        }
      }
    });

    /**
     * Updates the URL with the selected branch parameter
     * @param {string} branch - The branch slug to add to URL
     */
    function updateUrl(branch) {
      const url = new URL(window.location);

      if (branch && branch !== 'view-all') {
        url.searchParams.set('branch', branch);
      } else {
        url.searchParams.delete('branch');
      }

      history.replaceState({}, '', url);
    }

  }
}
