import Select from './Select.js';

class ItemsIndex {

  constructor({postTypes, container, filters = false, clearButton = false, searchBar = false}) {

    this.action = 'get_items';

    this.postTypes = postTypes;
    this.container = container;
    this.clearButton = clearButton;
    this.searchBar = searchBar;
    this.filters = this.initFilters(filters);

    this.initPagination();

  }

  /**
   * Initialize filters if they are present
   */
  initFilters = (filters) => {

    // Check if filters exist
    if(filters === false) {

      return false;

    }

    // Create array of filters
    filters = filters instanceof NodeList ?  [...filters] : [filters];

    // Create Select instances for each filter
    let filterObjects = filters.map(filter => new Select(filter));

    // Listen for 'filter' event on the filter
    filterObjects.forEach(filter => filter.element.addEventListener('filter', this.handleFilterChange));

    // Listen for 'search' and 'input' event on the searchbar
    if(this.searchBar) {

      this.searchBar.addEventListener('submit', this.handleSearch);
      this.searchBar.addEventListener('input', this.checkInput);

    }


    // Listen for click event on the clear button if it exists
    if(this.clearButton) {

      this.clearButton.addEventListener('click', this.clearFilters);

    }

    return filterObjects;

  }


  /**
   * Checks if the input is empty and if so, call handleSearch function
   */
  checkInput = () => {

    if(this.searchBar.querySelector('[data-search-value]').value == '') {

      this.handleSearch()

    }

  }

  /**
   * Initialize pagination if the index container is present
   */
  initPagination = () => {
    if(this.container) {

      this.container.querySelectorAll('[data-pagination] a').forEach(a => { a.addEventListener('click', this.handlePaginationClick.bind(null, a), {once: true}) });
    }
  }

  /**
   * Filter change callback
   */
  handleFilterChange = () => {

    this.clearSearch();
    this.fetchItems();

    // Toggle clearButton visibility
    if(this.clearButton) {

      // Check if any filter is active
      let anyFilterActive = this.filters.filter(filter => filter.isFiltered).length;

      this.toggleClearButton(!anyFilterActive);

    }
  }

  /**
 * Search callback
 */
  handleSearch = (event) => {

    if(event) {
      event.preventDefault();
    }

    this.resetFilters();
    this.fetchItems(1, 'search');

  }

  /**
   * Clear all filters
   */
  clearFilters = () => {

    // Clear all filters
    this.filters.forEach(filter => filter.clear());

    // Run filter change callback
    this.handleFilterChange();

  }

  /**
   * Reset filters without submitting
   */
  resetFilters = () => {

    // Clear all filters
    this.filters.forEach(filter => filter.clear());

  }

  /**
   * Clear the search field
   */
  clearSearch = () => {

    this.searchBar.querySelector('[data-search-value]').value = '';

  }

  /**
   * Toggle visibility of clearButton
   * @param {boolean} toggle
   */
  toggleClearButton = (toggle) => {

    if(this.clearButton) {

      this.clearButton.classList.toggle('hidden', toggle);

    }
  }

  /**
   * Pagination click callback
   * @param {HTMLElement} link
   * @param {Event} e
   */
  handlePaginationClick = (link, e) => {

    e.preventDefault();
    this.fetchItems(link.dataset.paged);

  }

  /**
   * Fetch items
   * @param {int} paged
   */
  fetchItems = (paged = 1, type = 'filter') => {

    // Setup fetch url params
    let queryParams = {
      'action': this.action,
      'post_types': this.postTypes,
      'paged': paged,
      'limit': this.container.dataset.limit,
    };

    // Create a urlParams object from filter form values
    let urlParams = {};

    if(type == 'filter' && this.filters) {

      this.filters.forEach(filter => {

        // Fill queryParams and urlParams with value
        queryParams[filter.getName()] = filter.getValue();

        console.log(filter);

        // Only fill the urlParams if there are any filters active
        if(filter.isFiltered) {

          urlParams[filter.getUrlParam()] = filter.getValue();

        }

        // Add (optional) hidden inputs to the queryParams
        filter.element.querySelectorAll('input[type=hidden]').forEach((input) => {

          queryParams[input.name] = input.value;

        });

      });
    }


    // Add search data
    let searchValue = this.searchBar.querySelector('[data-search-value]').value;

    if(type == 'search' && searchValue) {

      queryParams['s'] = searchValue;
      queryParams['engine'] = this.searchBar.dataset.searchEngine;

    }


    // Add loading class to results container
    this.container.classList.add('loading');

    // Fetch the articles using the queryParams
    fetch(`${window.ajaxUrl}?${new URLSearchParams(queryParams).toString()}`)
      .then(response => response.json() )
      .then(data => {


        // Insert the fetched html in the results container
        this.container.innerHTML = data.html;

        // Re-init pagination
        this.initPagination();

        // Lift the loading class from the results container
        this.container.classList.remove('loading');

        // Update the browser url
        this.updateUrl(paged, urlParams);

      });
  }

  /**
   * Add pagination and filters to the url
   * @param {int} paged
   * @param {object} urlParams
   */
  updateUrl = (paged, urlParams) => {

    // Set paged
    let pattern = new RegExp(`${window.pagination_base}/\\d+`);
    let paginationPath = paged > 1 ? window.pagination_base + '/' + paged : '';
    let newPath = window.location.pathname.match(pattern) ? (window.location.pathname.replace(pattern, paginationPath)) : window.location.pathname + paginationPath;

    // Set filter params
    let queryParams = new URLSearchParams(urlParams);
    newPath += queryParams.toString() ? '?' + queryParams.toString() : '';

    // Replace state in history
    history.replaceState(null, null, newPath);

  }
}

export default ItemsIndex;
