import {Autoplay, Navigation} from 'swiper/modules';
import Swiper from 'swiper';

class LogoSlider {

  constructor(container) {

    this.swiper = container;

    this.initSwiper();

  }


  /**
   * Initialize swiper instance
   */
  initSwiper() {

    new Swiper(this.swiper, {
      modules: [ Autoplay, Navigation],
      slidesPerView: 3,
      spaceBetween: 24,
      loop: true,
      speed: 3700,
      waitForTransition: false,
      loopAdditionalSlides: 6,
      autoplay: {
        delay: 0,
        disableOnInteraction: true,
      },
      allowTouchMove: false,
      breakpoints: {
        500: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 5,
        },
        768: {
          slidesPerView: 6,
          spaceBetween: 48,
        },
      },
    });
  }

}

export default LogoSlider;
